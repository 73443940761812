<template>
  <section>
    <v-img v-if="!evidenceSelected.content_url" class="ma-5 rounded" :src="'data:image/png;base64,'+evidenceSelected.kind.image_base64" lang="Imagem do veículo" />
    <p
      class="text-subtitle-1 font-weight-bold text-center pa-0 mt-4 mr-8"
      style="width: 100%"
    >
      {{ evidenceSelected.kind.name }}
    </p>
    <p
      v-if="!evidenceSelected.content_url"
      class="text-subtitle-2 font-weight-medium text-center pa-0 mt-4 mr-8 mb-10"
      style="width: 100%"
    >
      {{ evidenceSelected.kind.description }}
    </p>
  </section>
</template>
<script>
export default {
  name: "instructions",
  props: {
    evidenceSelected: {
      type: Object,
    },
  },
};
</script>
