<template>
  <section v-if="requirement">
    <v-btn class="mx-0" fab small text @click="emitStep()">
      <v-icon dark color="success" size="25"> arrow_back_ios </v-icon>
    </v-btn>
    <div
      class="d-flex flex-column align-center ma-0 pa-0"
      style="overflow-y: hidden"
    >
      <div v-if="instructions" class="instructions">
        <Instructions :evidenceSelected="requirement"></Instructions>
        <p
            v-if="refuseReason()"
            class="text-subtitle-2 font-weight-medium text-center pa-0"
        >
          Motivo da recusa: {{ requirement.refuse_reason }}
        </p>
        <figure v-if="requirement.content_url" class="evidence-media-figure">
          <video
            :src="requirement.content_url"
            class="rounded"
            controls
            style="height: 300px;"
          />
          <figcaption>
            <v-btn
                icon
                color="green"
                @click="removeImage()"
              >
                <v-icon>delete</v-icon>
              </v-btn>
          </figcaption>
        </figure>

        <v-btn
          v-if="!checkDevice()"
          dark
          block
          rounded
          color="#067b26"
          class="button"
          @click="take()"
          :disabled="!!requirement.content_url"
        >
          Continuar
        </v-btn>
      </div>
      <div
        v-if="isCameraOpen && !checkDevice()"
        class="d-flex flex-grow-1 flex-shrink-0 flex-column align-center"
      >
        <p
          class="text-subtitle-1 font-weight-medium text-center pa-0 mt-4 mr-8"
          style="width: 100%"
        >
          {{ requirement.kind.name }}
        </p>
        <v-chip
          class="text-subtitle-2 font-weight-medium text-center mr-8 mb-5"
          outlined
          :color="
            filterIcons(requirement.status.original_value)[0].color
          "
          >{{ requirement.status.translated_value }}</v-chip
        >
      </div>
    </div>
    <div v-if="isCameraOpen && !checkDevice()" id="startButton" class="button">
      <p class="ma-0 mr-5 text-end text-subtitle-2">
        tempo de gravação:
        {{ isRecording == true ? recordingTime : "0" }} segundos
      </p>
      <video
        class="rounded"
        ref="preview"
        width="100%"
        height="400"
        autoplay
        muted
      ></video>
      <section class="d-flex flex-column align-center justify-center">
        <p
          v-if="requirement.refuse_reason != null"
          class="ma-0 mr-5 mb-3 text-end text-subtitle-2"
        >
          Motivo da recusa: {{ requirement.refuse_reason }}
        </p>
        <v-btn
          icon
          @click="startRecording"
          :disabled="isRecording"
          class="mr-3 mt-4"
          v-if="!isRecording"
        >
          <v-icon class="green white--text rounded-circle pa-6" dark medium
            >videocam</v-icon
          >
        </v-btn>
        <v-btn icon @click="stopRecording" v-if="isRecording">
          <v-icon class="red white--text rounded pa-5">stop_circle</v-icon>
        </v-btn>
      </section>
    </div>
    <div
      v-if="previewVideo && !checkDevice()"
      id="stopButton"
      class="d-flex flex-column"
    >
      <video
        class="rounded"
        ref="recording"
        width="100%"
        height="400"
        controls
      ></video>
      <section class="d-flex justify-space-between mx-3 mt-3">
        <v-btn icon @click="cancelRecording" small :disabled="loadingBtn">
          <v-icon class="red white--text rounded pa-4">cancel</v-icon>
        </v-btn>
        <v-btn icon @click="saveRecording" small :loading="loadingBtn">
          <v-icon class="green white--text rounded pa-4">check_circle</v-icon>
        </v-btn>
      </section>
    </div>
    <div v-if="checkDevice()">
      <CaptureVideoMobileVue :geolocation="geolocation" :evidenceSelected="requirement" />
    </div>
  </section>
</template>
<script>
import PedidoService from "@/services/pedidos.service";
import GlobalStore from "@/store/modules/global";
import CaptureVideoMobileVue from "./CaptureVideoMobile.vue";
import Instructions from "./Instructions.vue";

export default {
  name: "CaptureVideo",
  props: {
    requirementKey: { type: String },
    geolocation: {
      type: Object
    }
  },
  components: {
    CaptureVideoMobileVue,
    Instructions,
  },
  data: () => ({
    isCameraOpen: false,
    icons: [
      { status: "pending", icon: "error", color: "orange" },
      { status: "unverified", icon: "schedule", color: "gray" },
      { status: "done", icon: "check_circle", color: "green" },
      { status: "refused", icon: "cancel", color: "red" },
    ],
    isRecording: false,
    mediaRecorder: null,
    recordedChunks: [],
    cameraStream: null,
    recordingTime: 0,
    timerInterval: null,
    maxRecordingTime: 10,
    blob: "",
    loadingBtn: false,
    instructions: true,
    previewVideo: false,
    requirement: null
  }),
  mounted() {
   this.fechRequirement()
  },
  methods: {
    startCamera() {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then((stream) => {
          this.cameraStream = stream;
          this.$refs.preview.srcObject = stream;
        })
        .catch((error) => {
          if (error.name === "NotFoundError") {
            // "Câmera ou microfone não encontrados. Não é possível iniciar a câmera."
          } else {
            error;
          }
        });
    },
    startRecording() {
      if (!this.$refs.preview.srcObject) {
        this.startCamera();
      }

      this.mediaRecorder = new MediaRecorder(this.$refs.preview.srcObject);
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };
      this.mediaRecorder.onstop = () => {
        const recordedBlob = new Blob(this.recordedChunks, {
          type: "video/webm",
        });
        this.$refs.recording.src = URL.createObjectURL(recordedBlob);
        this.blob = recordedBlob;
        // this.$refs.downloadButton.href = this.$refs.preview.src;
        // this.$refs.downloadButton.download = "RecordedVideo.mp4";
        this.stopCamera();
      };
      this.recordingTime = 0;
      this.timerInterval = setInterval(() => {
        this.recordingTime += 1;
        if (this.recordingTime >= this.maxRecordingTime) {
          this.stopRecording();
        }
      }, 1000);
      this.mediaRecorder.start();
      this.isRecording = true;
      // this.isCameraOpen = false
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.stopCamera();
    },
    async saveRecording() {
      this.loadingBtn = true;
      const {
        key,
        kind: { name },
      } = this.requirement;
      try {
        var formData = new FormData();
        const latitude = this.geolocation.latitude ? this.geolocation.latitude : ''
        const longitude = this.geolocation.longitude ? this.geolocation.longitude : ''

        formData.append('latitude', latitude)
        formData.append('longitude', longitude)
        formData.append("content", this.blob, name + ".webm");

        const res = await PedidoService.updateEvidences(
          key,
          formData
        ).toPromise();
        this.emitStep(3);
        this.fechRequirement()
        this.loadingBtn = false;
      } catch (erro) {
        var msg = JSON.parse(erro.request.responseText);
        msg.errors.forEach((m) => {
          GlobalStore.setSnackBar({
            show: true,
            type: "error",
            message: m,
          });
        });
        this.loadingBtn = false;
      }
    },
    cancelRecording() {
      this.recordedChunks = [];
      this.stopCamera();
      this.isRecording = false;
      this.startCamera();
      this.isCameraOpen = true;
      this.previewVideo = false

      clearInterval(this.timerInterval);
      this.recordingTime = 0;
    },
    stopCamera() {
      if (this.cameraStream && this.$refs.preview) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
        this.$refs.preview.srcObject = null;
        this.cameraStream = null;
        this.isCameraOpen = false;
        this.previewVideo = true
      }
    },
    filterIcons(status) {
      const iconSelected = this.icons.filter((icon) => icon.status == status);
      return iconSelected;
    },
    verifyTypeFile() {
      return this.requirement.kind.content_type == "image";
    },
    refuseReason() {
      return (
        this.requirement.status.original_value == "refused" &&
        !this.isPhotoTaken
      );
    },
    emitStep(step = 1) {
      this.stopCamera();
      this.$emit('step', step);
    },
    checkDevice() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true; // true está utilizando celular
      } else {
        return false; // false não é celular
      }
    },
    take() {
      this.isCameraOpen = true;
      this.instructions = false
      this.startCamera();
    },
    fechRequirement() {
      this.isLoading = true
      PedidoService.getRequirementByKey(this.requirementKey).subscribe(res=> {
        this.isLoading = false
        this.requirement = res
      }, err => {
        this.isLoading = false
      })
    },
    removeImage() {
      const { key } = this.requirement;
      this.isLoading = true
      PedidoService.contentDetach(key).subscribe(res=> {
        this.fechRequirement()
        this.isLoading = false
      }, err => {
        this.isLoading = false
      })
    },
  }
};
</script>
