<template>
  <div class="card-evidences d-flex justify-center">
    <section class="loading" v-if="isLoading">
      <v-progress-circular
        indeterminate
        color="success"
      ></v-progress-circular>
      <span>Carregando informações...</span>
    </section>
    <v-card width="600" v-if="autovistoria" style="position: relative;">
      <div class="header-evidences" style="width: 100%;">
        <div class="d-flex flex-column">
          <v-toolbar class="elevation-0">
            <img
            src="../../../assets/images/logo.svg"
            alt="Logo Sistransce"
            class="login-page-form__logo"
            />
          </v-toolbar>
          <v-divider></v-divider>
          <div class="elevation-1 pb-5">
            <div class="d-flex justify-space-around mb-1">
              <p class="ma-0 mt-3 text-subtitle-1 text-uppercase">
                <label class="ma-0 font-weight-medium mr-1">Pedido:</label
                >{{ autovistoria.solicitation.id }}
              </p>
              <p class="ma-0 mt-3 text-subtitle-1 text-uppercase">
                <label class="ma-0 font-weight-medium mr-1">Placa:</label>
                {{ autovistoria.solicitation.plate }}
              </p>
            </div>
            <div class="d-flex justify-center align-center">
              <div
                :class="filterColorStatus(autovistoria.status.original_value)"
              ></div>
              <p
                class="ma-0 mt-1 text-subtitle-1 text-uppercase text-right font-weight-medium"
              >
                {{ autovistoria.status.translated_value }}
              </p>
            </div>
            <v-container v-if="autovistoria.reject_reason" fluid>
              <v-text class="text-justify">
                <span class="text-subtitle-1 font-weight-bold">Motivo:</span> {{ autovistoria.reject_reason }}
              </v-text>
            </v-container>
          </div>
        </div>
      </div>
      <v-card-text class="app-card-content">
        <v-list v-if="step == 1" dense>
          <v-list-item-group color="grey darken-1">
            <v-list-item
              v-for="(evidences, index) in autovistoria.requirements"
              :key="index"
              @click="selectEvidence(evidences)"
              class="pa-3 elevation-1"
              color="grey darken-1"
            >
              <v-list-item-icon>
                <v-icon
                  :color="filterIcons(evidences.status.original_value).color"
                  >{{
                    filterIcons(evidences.status.original_value).icon
                  }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  {{ evidences.kind.name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-chip
                    class="mt-2"
                    :ripple="false"
                    outlined
                    :color="
                      filterIcons(evidences.status.original_value).color
                    "
                    >{{ evidences.status.translated_value }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="green">arrow_forward_ios</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-if="step == 2">
          <CaptureImages
            v-if="!isLoading"
            :requirementKey="evidenceSelected.key"
            :geolocation="geolocation"
            @updateEvidences="detailsAutovistoriaPerKey()"
            @step="emitStep"
          />
        </div>
        <div v-if="step == 3">
          <CaptureVideoVue
            v-if="!isLoading"
            :requirementKey="evidenceSelected.key"
            :geolocation="geolocation"
            @step="emitStep"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CaptureImages from "../components/CaptureImages.vue";
import PedidoService from "@/services/pedidos.service";
import { StatusPedido } from "@/models/status-pedido.enum";
import CaptureVideoVue from "../components/CaptureVideo.vue";

export default {
  name: "FormEvidencias",
  components: {
    CaptureImages,
    CaptureVideoVue,
  },

  data: () => ({
    autovistoria: null,
    isLoading: false,
    statusPedido: StatusPedido,
    icons: [
      { status: "pending", icon: "error", color: "orange" },
      { status: "unverified", icon: "schedule", color: "gray" },
      { status: "done", icon: "check_circle", color: "green" },
      { status: "refused", icon: "cancel", color: "red" },
    ],
    statusEvidences: [
      { status: "default", color: "icon-pulse grey" },
      { status: "awaiting_evidences", color: "icon-pulse orange" },
      { status: "awaiting_operator_analise", color: "icon-pulse orange" },
      { status: "awaiting_engineer_analise", color: "icon-pulse orange" },
      { status: "making_operator_analise", color: "icon-pulse orange" },
      { status: "making_engineer_analise", color: "icon-pulse orange" },
      { status: "deferred", color: "icon green" },
      { status: "undeferred", color: "icon red" },
    ],
    step: 1,
    evidenceSelected: null,
    geolocation: {
      latitude: null,
      longitude: null
    }
  }),
  computed: {
    key: {
      get() {
        const {
          params: { key },
        } = this.$route;

        return key;
      },
    },
  },
  mounted() {
    this.detailsAutovistoriaPerKey();
    this.getCurrentGeoLocation()
  },
  methods: {
    detailsAutovistoriaPerKey() {
      this.isLoading = true
      PedidoService.detailsAutovistoria(this.key).subscribe(
        (res) => {
          this.autovistoria = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    },
    getCurrentGeoLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude} = position.coords

        this.geolocation = {...this.geolocation, latitude, longitude }
      });
    },
    filterIcons(status) {
      const iconSelected = this.icons.filter((icon) => icon.status == status);
      return iconSelected[0];
    },
    filterColorStatus(status) {
      const colorSelected = this.statusEvidences.filter((item) => item.status == status);
      if(colorSelected.length == 0) return this.statusEvidences[0].color ;
      return colorSelected[0].color;
    },
    selectEvidence(evidence) {
      this.evidenceSelected = evidence;

      if (this.canChangeRequirement(evidence)) {
        this.step = this.contentTypeIsImage(evidence) ? 2 : 3;
      }
    },
    contentTypeIsImage({kind: { content_type }}) {
      return content_type === 'image'
    },
    canChangeRequirement(evidence){
      const { status: { original_value } } = evidence
      const { status } = this.autovistoria
      const statuses = ['awaiting_evidences', 'awaiting_operator_analise', 'making_operator_analise']

      return original_value != 'done' && statuses.includes(status.original_value)
    },
    emitStep(val) {
      this.step = val;
      this.detailsAutovistoriaPerKey();
    },
  }
};
</script>

<style scoped>
.label-text {
  font-size: 0.8rem;
}
p {
  font-size: 0.9rem;
}
.login-page-form__logo {
  width: 10rem;
}
.icon-pulse {
  /* background: black; */
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;

  box-shadow: 0 0 0 0 rgb(135, 103, 30);
  transform: scale(1);
  animation: pulse 2s infinite;
}
.icon {
  background: black;
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(190, 171, 86, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(193, 193, 193, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(157, 157, 157, 0);
  }
}
.header-evidences {
  position: fixed;
  top: 0px;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}
.app-card-content{
  margin-top: 10.5rem ;
}
</style>
