<template>
  <div class="web-camera-container d-flex flex-column" v-if="requirement">
    <v-btn class="mx-0" fab small text @click="emitStep()">
        <v-icon dark color="success" size="25"> arrow_back_ios </v-icon>
    </v-btn>
    <div v-if="instructions" class="instructions">
      <Instructions :evidenceSelected="requirement"/>
      <p
          v-if="refuseReason()"
          class="text-subtitle-2 font-weight-medium text-center pa-0"
        >
        Motivo da recusa: {{ requirement.refuse_reason }}
      </p>

      <figure v-if="requirement.content_url" class="evidence-media-figure">
        <img :src="requirement.content_url" :alt="requirement.kind.code">
        <figcaption>
          <v-btn
              icon
              color="green"
              @click="removeImage()"
            >
              <v-icon>delete</v-icon>
            </v-btn>
        </figcaption>
      </figure>

      <v-btn v-if="!checkDevice()" dark block rounded color="#067b26" class="button" @click="take()" :disabled="!!requirement.content_url">
        {{ requirement.content_url? 'Nova Captura' : 'Continuar' }}
      </v-btn>
    </div>
    <div
      v-if="isCameraOpen && !instructions"
      v-show="!isLoading"
      :class="{ flash: isShotPhoto }"
    >
      <div class="d-flex align-center ma-0 pa-0" style="overflow-y: hidden">
        <div class="d-flex flex-grow-1 flex-shrink-0 flex-column align-center">
          <p
            class="text-subtitle-1 font-weight-medium text-center pa-0 mt-4 mr-8"
            style="width: 100%"
          >
            {{ requirement.kind.name }}
          </p>
          <v-chip
            v-if="!isPhotoTaken"
            class="text-subtitle-2 font-weight-medium text-center mr-8 mb-5"
            outlined
            :color="
              filterIcons(requirement.status.original_value)[0].color
            "
            >{{ requirement.status.translated_value }}</v-chip
          >
        </div>
      </div>
      <div
        id="card-camera"
        class="d-flex flex-column justify-center elevation-0"
        v-if="verifyTypeFile() && !checkDevice()"
      >
        <video v-show="!isPhotoTaken" ref="camera" autoplay></video>
        <canvas
          v-show="isPhotoTaken"
          id="photoTaken"
          ref="canvas"
          :width="400"
          :height="300"
        >
        </canvas>
        <p class="text-subtitle-2 font-weight-medium text-center pa-0 mt-4">
          {{ requirement.kind.description_value }}
        </p>
        <p
          v-if="refuseReason()"
          class="text-subtitle-2 font-weight-medium text-center pa-0"
        >
          Motivo da recusa: {{ requirement.refuse_reason }}
        </p>
        <v-card-actions class="d-flex flex-column justify-center">
          <section
            v-if="verifyTypeFile()"
            style="width: 100%"
            class="d-flex flex-column justify-center"
          >
            <button
              v-if="!isPhotoTaken"
              type="button"
              class="button"
              @click="takePhoto"
            >
              <v-icon
                class="green white--text rounded-circle pa-3"
                dark
                medium
                v-text="'photo_camera'"
              />
            </button>
            <div v-else class="d-flex justify-space-between mt-3">
              <v-btn
                small
                color="error"
                class="white--text"
                title="Cancelar"
                :disabled="loader"
                @click="isPhotoTaken = false"
              >
                <v-icon color="white" size="20"> cancel </v-icon>
              </v-btn>
              <v-btn
                small
                color="success"
                class="white--text mr-2"
                title="Enviar"
                :disabled="loader"
                :loading="loader"
                @click="send()"
              >
                <v-icon color="white" size="20"> check_circle </v-icon>
              </v-btn>
            </div>
          </section>
          <section
            v-if="requirement.kind.content_type == 'video'"
          ></section>
        </v-card-actions>
      </div>
    </div>
    <div v-if="checkDevice()">
      <CapturePhotoMobileVue :geolocation="geolocation" :evidenceSelected="requirement"/>
    </div>
  </div>
</template>

<script>
import PedidoService from "@/services/pedidos.service";
import GlobalStore from "@/store/modules/global";
import Instructions from "./Instructions.vue";
import CapturePhotoMobileVue from './CapturePhotoMobile.vue';

export default {
  name: "CaptureImages",
  props: {
    requirementKey: {
      type: String,
    },
    open: {
      type: Boolean,
    },
    geolocation: {
      type: Object
    }
  },
  components: {
    CapturePhotoMobileVue,
    Instructions
  },
  data: () => ({
    isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    link: "#",
    file: {},
    requirement: null,
    icons: [
      { status: "pending", icon: "error", color: "orange" },
      { status: "unverified", icon: "schedule", color: "gray" },
      { status: "done", icon: "check_circle", color: "green" },
      { status: "refused", icon: "cancel", color: "red" },
    ],
    updateEvidences: "",
    loader: false,
    instructions: true
  }),
  watch: {
    updateEvidences: function (newVal, oldVal) {
      this.$emit("updateEvidences", newVal);
    },
  },
  mounted() {
   this.fechRequirement()
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.instructions = true
        this.stopCameraStream();
      } else {
        // this.info = false
        this.dataEvidence = "";
        this.isCameraOpen = true;
        this.instructions = false
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: { facingMode: "environment" },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream ;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 10;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 400, 400);
    },

    async send() {
      this.loader = true;
      try {
        const success = await this.uploadImage();
        this.loader = false;
        this.updateEvidences = this.requirement.key;
        this.emitStep(2);
        this.fechRequirement()
        success ? this.toggleCamera() : "Falha ao enviar a imagem";
      } catch (erro) {
        this.loader = false;
        var msg = JSON.parse(erro.request.responseText);
        msg.errors.forEach((m) => {
          GlobalStore.setSnackBar({
            show: true,
            type: "error",
            message: m,
          });
        });
      }
    },
    async uploadImage() {
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 400, 400);
      const {
        key,
        kind: { name },
      } = this.requirement;

      let formData = new FormData();

      const latitude = this.geolocation.latitude ? this.geolocation.latitude : ''
      const longitude = this.geolocation.longitude ? this.geolocation.longitude : ''

      formData.append('latitude', latitude)
      formData.append('longitude', longitude)

      return new Promise(async (resolve, reject) => {
        context.canvas.toBlob(async function (blob) {
          formData.append("content", blob, name)

          try {
            const res = await PedidoService.updateEvidences(
              key,
              formData
            ).toPromise();
            if (res) {
              resolve(true);
            } else {
              resolve(false);
            }
          } catch (error) {
            reject(error);
          }
        }, "image/png");
      });
    },
    fechRequirement() {
      this.isLoading = true
      PedidoService.getRequirementByKey(this.requirementKey).subscribe(res=> {
        this.isLoading = false
        this.requirement = res
      }, err => {
        this.isLoading = false
      })
    },
    removeImage() {
      const { key } = this.requirement;
      this.isLoading = true
      PedidoService.contentDetach(key).subscribe(res=> {
        this.fechRequirement()
        this.isLoading = false
      }, err => {
        this.isLoading = false
      })
    },
    filterIcons(status) {
      const iconSelected = this.icons.filter((icon) => icon.status == status);
      return iconSelected;
    },
    verifyTypeFile() {
      return this.requirement.kind.content_type == 'image'
    },
    refuseReason() {
      return this.requirement.status.original_value == 'refused' && !this.isPhotoTaken
    },
    emitStep(step = 1) {
      this.$emit('step', step);
    },
    checkDevice() {
      if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      ){
          return true; // true está utilizando celular
      }
      else {
          return false; // false não é celular
      }
    },
    take() {
      this.toggleCamera()
    }
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.icon-photo {
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  border: dashed 1px rgb(76, 175, 80);
}
.v-card {
  overflow-y: hidden;
}
</style>
